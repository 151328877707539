.button {
  font: inherit;
  border: 1px solid transparent;
  padding: 10px 20px;
  border-radius: var(--radius-button);
  cursor: pointer;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:disabled {
  pointer-events: none;
}

.button:focus {
  /* Overlap the outline with the border */
  outline-offset: -1px;
}

.button[data-variant="filled"] {
  background: var(--ui-semantic-action-bg-primary);
  color: var(--color-bg);
}

.button[data-variant="filled"]:hover {
  background: var(--ui-semantic-action-bg-primary-hover);
}

.button[data-variant="filled"]:focus {
  /* Move the outline outside the button for visibility */
  outline-offset: 2px;
}

.button[data-variant="filled"]:disabled {
  background: var(--gray-300);
  color: var(--white);
}

.button[data-variant="icon"],
.button[data-variant="outline"] {
  border-color: var(--color-border);
  background: transparent;
  color: var(--color-text);
}

.button[data-variant="icon"] {
  padding: 0;
  min-width: var(--size);
  min-height: var(--size);
}

.button[data-variant="icon"]:hover,
.button[data-variant="outline"]:hover {
  background: var(--gray-100);
}

.button[data-variant="icon"]:disabled,
.button[data-variant="outline"]:disabled {
  opacity: 0.5;
}
