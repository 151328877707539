.outer {
  all: initial;
  color: var(--color-text);
  font-family: var(--font-sans);
  position: relative;
  cursor: pointer;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-button);
  padding: 5px 10px;
  user-select: none;
}

.outer:focus {
  outline: 2px solid var(--ui-semantic-action-bg-primary);
  outline-offset: 2px;
}

.profileWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.avatar {
  display: inline-block;
  border: 1px solid #ccc;
  background-image: var(--avatar-image);
  background-color: var(--ui-semantic-action-bg-primary);
  background-position: 50% 50%;
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  border: 2px solid transparent;
}

.menu {
  position: absolute;
  width: 100%;
  top: 45px;
  right: 0;
  display: block;
  margin: 0;
  padding: 10px 0px;
  border: 1px solid var(--color-border);
  background: #fff;
  border-radius: var(--radius-button);
  box-shadow: 0px 4px 8px rgba(0 0 0 / 10%);
}

.menu[hidden] {
  display: none;
}

.menuItem {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menuItem > a {
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
}

.menuItem > a:hover {
  color: var(--color-bg);
  background: var(--ui-semantic-action-bg-primary);
}
