.nav {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-template-areas: "logo navOptions profile";
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  background: white;
}

.nav a[href="/about"] {
  display: flex;
  align-items: center;
}

.logo {
  grid-area: logo;
}

.logo img {
  max-width: 260px;
  width: 100%;
}

.profile {
  grid-area: profile;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.login {
  padding: 10px 20px;
  font-size: 14px;
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 3px;
  background: var(--ui-semantic-action-bg-primary);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .nav {
    padding: 20px 20px 0 20px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "logo profile"
      "navOptions navOptions";
  }
}

.login:hover {
  opacity: 0.9;
}

@media (max-width: 1024px) {
  .nav {
    padding: 20px 20px 0 20px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "logo profile"
      "navOptions navOptions";
  }
}
