@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

:root {
  --white: #fff;
  --color-bg: var(--white);
  --color-text: #222;
  --font-sans: "Open Sans", sans-serif;
  --color-border: #888;
  --radius-button: 3px;
  --radius-well: 10px;
  --ui-semantic-action-bg-primary: hsl(234, 41%, 41%);
  --ui-semantic-action-bg-primary-hover: hsl(234, 41%, 31%);
  --ui-semantic-action-bg-selected: hsl(234, 50%, 96%);
  --gray-100: #eeeeee;
  --gray-300: #cccccc;
  --gray-400: #999999;
  --gray-500: #555555;
  --gray-700: #333333;
}

* {
  box-sizing: border-box;
}

html {
  scrollbar-color: var(--gray-700) var(--color-bg);
  /* Avoid having the scrollbar hide/show repeatedly */
  overflow-y: scroll;
}

body {
  background: var(--color-bg);
  color: var(--color-text);
  font-family: var(--font-sans);
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 100%;
  min-height: 100vh;
  line-height: 1.5;
  font-size: 16px;
}

button {
  font: inherit;
}

h1 {
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
}

ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

hr {
  border: 0;
  margin: 1rem 0;
  border-top: 2px solid rgb(0 0 0 / 10%);
}

a {
  color: var(--ui-semantic-action-bg-primary);
}

pre.debug {
  white-space: pre-wrap;
}

.grid-container {
  display: flex;
  flex-direction: column;
}

.body {
  flex: 1 1;
}

.linkButton {
  display: inline-flex;
  border-radius: var(--radius-button);
}

a:focus,
button:focus {
  outline: 2px solid var(--ui-semantic-action-bg-primary);
  outline-offset: 2px;
}

.grid-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.card {
  padding: 10px;
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-well);
}

.body {
  flex: 1 1;
}

.margin0 {
  margin: 0;
}

.spacer {
  padding: 10px;
}

@media (max-width: 768px) {
  .grid-container {
    height: auto;
  }
}

.button_button__sGggG {
  font: inherit;
  border: 1px solid transparent;
  padding: 10px 20px;
  border-radius: var(--radius-button);
  cursor: pointer;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_button__sGggG:disabled {
  pointer-events: none;
}

.button_button__sGggG:focus {
  /* Overlap the outline with the border */
  outline-offset: -1px;
}

.button_button__sGggG[data-variant="filled"] {
  background: var(--ui-semantic-action-bg-primary);
  color: var(--color-bg);
}

.button_button__sGggG[data-variant="filled"]:hover {
  background: var(--ui-semantic-action-bg-primary-hover);
}

.button_button__sGggG[data-variant="filled"]:focus {
  /* Move the outline outside the button for visibility */
  outline-offset: 2px;
}

.button_button__sGggG[data-variant="filled"]:disabled {
  background: var(--gray-300);
  color: var(--white);
}

.button_button__sGggG[data-variant="icon"],
.button_button__sGggG[data-variant="outline"] {
  border-color: var(--color-border);
  background: transparent;
  color: var(--color-text);
}

.button_button__sGggG[data-variant="icon"] {
  padding: 0;
  min-width: var(--size);
  min-height: var(--size);
}

.button_button__sGggG[data-variant="icon"]:hover,
.button_button__sGggG[data-variant="outline"]:hover {
  background: var(--gray-100);
}

.button_button__sGggG[data-variant="icon"]:disabled,
.button_button__sGggG[data-variant="outline"]:disabled {
  opacity: 0.5;
}

.profile_outer__9He8F {
  all: initial;
  color: var(--color-text);
  font-family: var(--font-sans);
  position: relative;
  cursor: pointer;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-button);
  padding: 5px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.profile_outer__9He8F:focus {
  outline: 2px solid var(--ui-semantic-action-bg-primary);
  outline-offset: 2px;
}

.profile_profileWrapper__Uk8gI {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.profile_avatar__KZUCj {
  display: inline-block;
  border: 1px solid #ccc;
  background-image: var(--avatar-image);
  background-color: var(--ui-semantic-action-bg-primary);
  background-position: 50% 50%;
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  border: 2px solid transparent;
}

.profile_menu__4Ftrb {
  position: absolute;
  width: 100%;
  top: 45px;
  right: 0;
  display: block;
  margin: 0;
  padding: 10px 0px;
  border: 1px solid var(--color-border);
  background: #fff;
  border-radius: var(--radius-button);
  box-shadow: 0px 4px 8px rgba(0 0 0 / 10%);
}

.profile_menu__4Ftrb[hidden] {
  display: none;
}

.profile_menuItem__1_bsq {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile_menuItem__1_bsq > a {
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
}

.profile_menuItem__1_bsq > a:hover {
  color: var(--color-bg);
  background: var(--ui-semantic-action-bg-primary);
}

.header_nav__7sJ38 {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-template-areas: "logo navOptions profile";
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  background: white;
}

.header_nav__7sJ38 a[href="/about"] {
  display: flex;
  align-items: center;
}

.header_logo__vVwaF {
  grid-area: logo;
}

.header_logo__vVwaF img {
  max-width: 260px;
  width: 100%;
}

.header_profile__B35Pv {
  grid-area: profile;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.header_login__e8_yb {
  padding: 10px 20px;
  font-size: 14px;
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 3px;
  background: var(--ui-semantic-action-bg-primary);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .header_nav__7sJ38 {
    padding: 20px 20px 0 20px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "logo profile"
      "navOptions navOptions";
  }
}

.header_login__e8_yb:hover {
  opacity: 0.9;
}

@media (max-width: 1024px) {
  .header_nav__7sJ38 {
    padding: 20px 20px 0 20px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "logo profile"
      "navOptions navOptions";
  }
}

